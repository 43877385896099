<template>
  <div class="home hide-overflow">
    <div id="container">
      <div class="loading" v-if="!pages">
        <h1>Loading Data</h1>
      </div>

<!-- HEADER -->
      <!-- Primary Survey -->
      <div class="primary fixedVH d-flex flex-column">
        <div class="row bg-danger text-light">
          <div class="col">
            <!-- Primary -->
            <div v-if="session.depth=='primary' && pages"> 
              <h1 class="py-3">{{ pages[session.currentPage].question }}</h1>
            </div>

            <!-- Observations -->
            <div v-if="session.depth=='observations'">
              <h1 class="py-3">Observations</h1>
            </div>

            <!-- Interventions -->
            <div v-if="session.depth=='interventions'"> 
              <h1 class="py-3">Interventions</h1>
            </div>

          </div>
        </div>

<!-- MAIN CONTENT -->

        <div class="row flex-grow-1 scroll-overflow">
          <div class="col">
            <!-- Primary -->
            <div v-if="session.depth=='primary' && pages">
              <span v-for="(item, key) in pages[session.currentPage].content" v-bind:key="key">
                <h3 :class="{'bg-secondary': item.points, 'text-light': item.points}" class="py-2 ">{{ item.text }}</h3>
                <p class="d-flex flex-column justify-content-center" v-if="item.points && item.points.length != 0">
                  <ul class="list-group" v-for="(point, index) in item.points" :key="index">
                    <li class="list-group-item">{{ point }}</li>
                  </ul>
                </p>
              </span>
            </div>

            <!-- Observations -->
            <div v-if="session.depth=='observations'">
              <h3 class="bg-secondary text-light py-2">Complete the following Observations</h3>
              <ul class="list-group">
                <li class="list-group-item" v-for="(ob, index) in pages[session.currentPage].observations" :key="index">{{ observations[ob].label }}</li>
              </ul>
            </div>

            <!-- Interventions -->
            <div v-if="session.depth=='interventions'"> 
              <h3 class="bg-secondary text-light py-2">Consider the following:</h3>
              <div class="skills" v-if="pages[session.currentPage].skills.length">
                <h3>Skills</h3>
                <div class="row d-flex justify-content-center">
                  <span v-for="(skill, index) in pages[session.currentPage].skills" :key="index">
                    <div class="btn-group w-75 my-1" role="group">
                      <a v-if="interventions[skill].cpg" :href="interventions[skill].cpg" target="_blank" class="btn btn-primary btn-block w-100"  :class="{'btn-success': interventions[skill].done, 'btn-warning': !interventions[skill].done}">
                        {{ interventions[skill].label }}
                        <i v-if="interventions[skill].cpg" class="bi bi-info-circle"></i>
                      </a>
                      <button v-else class="btn btn-secondary btn-block w-100" :class="{'btn-success': interventions[skill].done, 'btn-warning': !interventions[skill].done}">{{ interventions[skill].label }}</button>
                      <button role="button" class="btn btn-success" :class="{'btn-success': interventions[skill].done, 'btn-warning': !interventions[skill].done}" @click="interventions[skill].done = !interventions[skill].done">
                        <i v-if="interventions[skill].done" class="bi bi-check-square"></i>
                        <i v-else class="bi bi-square"></i>
                      </button>
                    </div>
                  </span>

                </div>
                
              </div>

              <div class="medications" v-if="pages[session.currentPage].medications.length">
                <h3>Medications</h3>
                <div class="row d-flex justify-content-center">
                  <span v-for="(med, index) in pages[session.currentPage].medications" :key="index">
                    <div class="btn-group w-75 my-1" role="group">
                      <a v-if="medications[med].cpg" :href="medications[med].cpg" target="_blank" class="btn btn-primary btn-block w-100" :class="{'btn-success': medications[med].done, 'btn-warning': !medications[med].done}">
                        {{ medications[med].label }}
                        <i v-if="medications[med].cpg" class="bi bi-info-circle"></i>
                      </a>
                      <button v-else class="btn btn-secondary btn-block w-100" :class="{'btn-success': medications[med].done, 'btn-warning': !medications[med].done}">{{ medications[med].label }}</button>
                      <button role="button" class="btn btn-success" :class="{'btn-success': medications[med].done, 'btn-warning': !medications[med].done}" @click="medications[med].done = !medications[med].done">
                        <i v-if="medications[med].done" class="bi bi-check-square"></i>
                        <i v-else class="bi bi-square"></i>
                      </button>
                    </div>
                  </span>

                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- FOOTER -->
        <div class="row" id="footer">
          <div class="col">

            <!-- Primary -->
            <div v-if="session.depth=='primary' && pages"> 
              <div class="btn-group-vertical w-100">
                <button v-for="(btn, index) in pages[session.currentPage].options" :key="index" class="btn w-100 p-4" :class="btn.class" @click="advance(btn.action)">
                  {{ btn.label }}
                </button>
              </div>
            </div>

            <!-- Observations -->
            <div v-if="session.depth=='observations'">
              <button class="btn btn-success w-100 p-4" @click="advance('interventions')">I have completed all obs</button>
            </div>

            <!-- Interventions -->
            <div v-if="session.depth=='interventions'"> 
              <button class="btn btn-success w-100 p-4" @click="finishSection();advance('primary')">I have considered all options</button>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  methods: {
    advance(page) {
      if( page == "reload") {
        location.reload();
      }
      if (["primary", "observations", "interventions"].includes(page)) {
        this.session.depth = page;
      }else{
        this.session.currentPage = page;
      }
    },
    finishSection() {
      let currentKey = Object.keys(this.pages).indexOf(this.session.currentPage);

      this.session.currentPage = Object.keys(this.pages)[currentKey + 1];
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--ivh', `${window.innerHeight}px`);
    window.addEventListener('resize',function(){
      document.documentElement.style.setProperty('--ivh', `${window.innerHeight}px`);
    });
    
    //Load data
    // eslint-disable-next-line
    this.pages = loadedPages;
    // eslint-disable-next-line
    this.observations = loadedObs;
        // eslint-disable-next-line
    this.interventions = loadedInterventions;
        // eslint-disable-next-line
    this.medications = loadedMedications;
  },
  data: function () {
  
    return{
      session: {
        currentPage: "danger",
        depth: "primary",
      },
      pages: false,
      observations: false,
      interventions: false,
      medications: false,
    }
  },
} 
</script>
