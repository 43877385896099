import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { bootstrap } from "bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css";

console.log(bootstrap);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
